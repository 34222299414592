.cssHMenu{
    margin: 5px;
}

.cssMenuIcon{
    width: 30px;
    height: 30px;
}

.cssMenuIconContainer{
    width: 30px;
    height: 30px;
}

.cssHMenuHeader {
    display: flex;
    align-items: center;
 }

 .cssMenuTitle{
    font-size: larger;
    margin-left: 5px;
 }
 
.cssHMenuList {
    display: flex;
    overflow-x: scroll;
}

.cssHMenuList::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
}

.cssHMenuList:hover::-webkit-scrollbar {
   width: 8px;
}

.cssHMenuList:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2)
 }
