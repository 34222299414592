.cssCardFrame {
    margin: 4px;
    border: 1px;
    border-color: lightgray;
    border-style: double;
    border-radius: 5px;
}

.cssCardFrame:hover {
    /**/
    border-color: gray;
}

.cssCard {
    width: 250px;
    height: 300px;
    margin: 4px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    overflow-y: scroll;
}

/* .cssCard::-webkit-scrollbar {
    width: 8px;
} */

/* .cssCard:hover::-webkit-scrollbar {
   width: 8px;
} */

.cssCardHeader {
    display: flex;
}

.cssCardContent {
    width: 100%;
    height: 100%;
}

.cssControlContainer{
    width: 100%;
    height: 100%;
    text-align: center;
}